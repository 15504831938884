import React, { useState } from "react";
import { Typography, Box, Divider } from "@material-ui/core";
// import { Link } from "react-router-dom";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Flip from "react-reveal/Flip";
import ReactTooltip from "react-tooltip";
import { GitHub, LinkedIn } from "../Images/Icons";
// import { Cv} from "../Images/Icons";
import useStyles from "./styles";
import { useTheme } from "@material-ui/core/styles";
import { useColor } from "../../contexts/ColorContext";
import Logo from "../Images/Logo";

const Contact = (props) => {
  const [dark, setDark] = useColor();
  const classes = useStyles(props);
  const {
    icon,
    titleDivider,
    emailLink,
    emailBorder,
    themeButton,
    container,
    linkText,
    emailText,
    copyIcon,
  } = classes;
  const theme = useTheme();

  const iconFill = theme.palette.primary.main;

  const [copy, setCopy] = useState({
    value: "karl@secen.ca",
    copied: false,
  });

  return (
    <Box
      display="flex"
      flexDirection="column"
      className={container}
      id="contact"
    >
      <Typography color="primary" align="center" variant="h2">
        Contact
      </Typography>
      <Divider className={titleDivider} />

      <Box
        mt={10}
        mb={6}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="row"
      >
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://github.com/karlkristopher"
          data-tip
          data-for="gitHub"
        >
          <ReactTooltip
            id="gitHub"
            place="top"
            effect="solid"
            type={dark ? "light" : null}
          >
            GitHub
          </ReactTooltip>
          <Box className={icon}>
            <GitHub fill={iconFill} />
          </Box>
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/in/karl-secen/"
          data-tip
          data-for="linkedIn"
        >
          <ReactTooltip
            id="linkedIn"
            place="top"
            effect="solid"
            type={dark ? "light" : null}
          >
            LinkedIn
          </ReactTooltip>
          <Box className={icon}>
            <LinkedIn fill={iconFill} />
          </Box>
        </a>
        {/* <Link
          data-tip
          data-for="CV"
          to="link to CV"
          target="_blank"
        >
          <ReactTooltip
            id="CV"
            place="top"
            effect="solid"
            type={dark ? "light" : null}
          >
            View CV
          </ReactTooltip>
          <Box className={icon}>
            <Cv fill={iconFill} />
          </Box>
        </Link> */}
      </Box>
      <a className={linkText} href="mailto:karl@secen.ca">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="row"
          mb={1}
          className={emailLink}
        >
          <MailOutlineIcon color="primary" />
          <Box ml={1}>
            <Typography
              className={emailText}
              color="primary"
              variant="subtitle1"
            >
              karl@secen.ca
            </Typography>
          </Box>
        </Box>
      </a>
      <CopyToClipboard
        text={copy.value}
        onCopy={() =>
          setCopy({
            ...copy,
            copied: !copy.copied,
          })
        }
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="row"
        >
          <Box className={emailBorder}>
            {!copy.copied ? (
              <>
                <FileCopyIcon color="primary" />
                <Typography color="primary" variant="subtitle2">
                  copy email
                </Typography>
              </>
            ) : (
              <>
                <FileCopyIcon className={copyIcon} />
                <Flip left>
                  <Typography variant="subtitle2"> copied.</Typography>
                </Flip>
              </>
            )}
          </Box>
        </Box>
      </CopyToClipboard>
      <Box onClick={() => setDark((props) => !props)} className={themeButton}>
        <Logo width="4.5rem" dark={dark} />
      </Box>
    </Box>
  );
};

export default Contact;
