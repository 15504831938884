import React from "react";
import Project from "./Project";
import { Typography, Box, Grid } from "@material-ui/core";
import { projectList } from "../../data/data";
import useStyles from "./styles";



const ProjectsCard = (props) => {

  const classes = useStyles(props);
  const { container, projectDisplay, projectContainer } = classes;

  const displayProjects = () =>
    projectList.map((project, i) => {
      return (
        <Grid className={projectDisplay} item xs={12} key={i}>
          <Project dark={props.dark} project={project} />
        </Grid>
      );
    });

  return (
    <Box className={container} id="projects">
      <Typography  color="primary" align="center" variant="h2">
        Work
      </Typography>
      <Grid className={projectContainer} container spacing={1}>
        {displayProjects()}
      </Grid>
    </Box>
  );
};

export default ProjectsCard;
