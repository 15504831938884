import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
  container: {
    width: "100%",
    "@media (max-width: 650px)": {
      maxWidth: "400px",
    },
  },
  skill: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    minWidth: "5.7rem",
    maxWidth: "5.7rem",
    margin: "1rem 0rem",
    "@media (max-width: 500px)": {
      margin: ".5rem 0rem",
    },
    "& svg": {
      height: "2rem",
      marginBottom: ".5rem",
      "@media (max-width: 500px)": {
        height: "1.2rem",
      },
    },
  },
}));

export default useStyles;
