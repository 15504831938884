import React, { useState } from "react";

import { Box } from "@material-ui/core";

import BackToTop from "../../components/BackToTop";
import Contact from "../../components/Contact";
import ProjectsCard from "../../components/ProjectsCard";
import TitleCard from "../../components/TitleCard";
import useStyles from "./styles";

const LandingPage = (props) => {
  const { card, container, cardContainer, title, projects, contact } =
    useStyles();

  const [showHeader, setShowHeader] = useState(false);

  window.onscroll = () => {
    let posTitle = document.querySelector("#title").getBoundingClientRect().top;
    let posContact = document
      .querySelector("#contact")
      .getBoundingClientRect().top;

    if (posTitle > -100 || posContact < 100) {
      setShowHeader(false);
    } else {
      setShowHeader(true);
    }
  };

  return (
    <Box className={container}>
      <BackToTop showHeader={showHeader} />
      <Box className={cardContainer}>
        <Box className={`${card} ${title}`}>
          <TitleCard />
        </Box>
        <Box className={`${card} ${projects}`}>
          <ProjectsCard dark={props.dark} />
        </Box>
        <Box className={`${card} ${contact}`}>
          <Contact dark={props.dark} />
        </Box>
      </Box>
    </Box>
  );
};

export default LandingPage;
