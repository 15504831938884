import React, { useEffect } from "react";
import Landing from "../Landing";
import { createMuiTheme } from "@material-ui/core/";
import { MuiThemeProvider } from "@material-ui/core";

import { primary, mutedInverse } from "../../themes/themes";
import { useColor } from "../../contexts/ColorContext";

const Dashboard = () => {
  const [dark] = useColor();
  const themeConfig = createMuiTheme(dark ? primary : mutedInverse);

  useEffect(() => {
    console.log("Hey there! Is there something you are looking for?");
    console.log(
      "If you have any questions, send me an email at karl@secen.ca."
    );
  }, []);

  return (
    <MuiThemeProvider theme={themeConfig}>
      <Landing dark={dark} />
    </MuiThemeProvider>
  );
};

export default Dashboard;
