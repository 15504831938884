import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
  icon: {
    color: theme.palette.primary.main,
  },
  container: (showHeader) => ({
    height: "auto",
    position: "fixed",
    bottom: showHeader ? "15px" : "-4rem",
    right: "15px",
    transition: "bottom 0.6s",
    display: "flex",
  }),
  upButton: {
    backgroundColor: theme.color.pink,
    marginLeft: ".5rem",
    borderRadius: "50%",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "3.5rem",
    height: "3.5rem",
    transition: "transform .1s",
    "&:active": {
      transform: "scale(.95)",
      backgroundColor: "#EC49A5",
    },
    "&:focus-visible": {
      outline: "none",
    },
  },
}));

export default useStyles;
