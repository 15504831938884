import React from "react";
import { Typography, Box } from "@material-ui/core";
import { GitHub } from "../../Images/Icons";
import LaunchIcon from "@material-ui/icons/Launch";
import { useTheme } from "@material-ui/core/styles";
import useStyles from "./styles";

const Project = (props) => {
  const theme = useTheme();
  const { primary } = theme.palette;
  const classes = useStyles(props);
  const {
    container,
    image,
    icon,
    descText,
    title,
    subtext,
    showIcons,
    iconBubble,
    linkText,
  } = classes;

  const { name, description, alt, urlSmall, gitHub, tech, link } =
    props.project;

  const descriptionText = description.split("\n").map((ele, i) => {
    return (
      <Typography
        className={subtext}
        align="center"
        color="primary"
        variant="body2"
        key={i}
      >
        {ele}
      </Typography>
    );
  });

  return (
    <Box className={container}>
      <Typography color="primary" className={title} align="center" variant="h3">
        {name}
      </Typography>
      <Box className={showIcons}>
        <a target="_blank" rel="noopener noreferrer" href={gitHub}>
          <Box className={iconBubble}>
            <Box className={icon}>
              <GitHub fill={primary.main} />
            </Box>
            <Typography className={linkText}>Code</Typography>
          </Box>
        </a>

        {link && (
          <a target="_blank" rel="noopener noreferrer" href={link}>
            <Box className={iconBubble}>
              <Box className={icon}>
                <LaunchIcon color="primary" />
              </Box>
              <Typography className={linkText}>Live</Typography>
            </Box>
          </a>
        )}
      </Box>
      <Box className={descText}>{descriptionText}</Box>

      <a target="_blank" rel="noopener noreferrer" href={gitHub}>
        <img className={image} src={urlSmall} alt={alt} />
      </a>
      <Box className={descText}>
        <Typography color="primary" align="center" variant="body2">
          {tech}
        </Typography>
      </Box>
    </Box>
  );
};

export default Project;
