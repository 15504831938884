import React from "react";

import { Box, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import {
  Css,
  Express,
  Firebase,
  Git,
  Html,
  Javascript,
  Jquery,
  Node,
  ReactIcon,
  SocketIo,
  Typescript,
  Webflow,
} from "../../Images/Icons";
import useStyles from "./styles";

const SkillsList = (props) => {
  const classes = useStyles(props);
  const { skill, container } = classes;
  const theme = useTheme();
  const iconsFill = theme.palette.primary.main;
  const titleFill = "primary";

  return (
    <>
      <Box
        display="flex"
        direction="row"
        flexWrap="wrap"
        justifyContent="center"
        alignItems="center"
        className={container}
      >
        <Box className={skill}>
          <Javascript fill={iconsFill} />
          <Typography color={titleFill} variant="body1">
            Javascript
          </Typography>
        </Box>
        <Box className={skill}>
          <Typescript fill={iconsFill} />
          <Typography color={titleFill} variant="body1">
            Typescript
          </Typography>
        </Box>
        <Box className={skill}>
          <ReactIcon fill={iconsFill} />
          <Typography color={titleFill} variant="body1">
            React
          </Typography>
        </Box>
        <Box className={skill}>
          <Node fill={iconsFill} />
          <Typography color={titleFill} variant="body1">
            Node.js
          </Typography>
        </Box>
        <Box className={skill}>
          <Express className={classes.express} fill={iconsFill} />
          <Typography color={titleFill} variant="body1">
            Express
          </Typography>
        </Box>
        <Box className={skill}>
          <Firebase fill={iconsFill} />
          <Typography color={titleFill} variant="body1">
            Firebase
          </Typography>
        </Box>
        <Box className={skill}>
          <Jquery fill={iconsFill} />
          <Typography color={titleFill} variant="body1">
            jQuery
          </Typography>
        </Box>
        <Box className={skill}>
          <SocketIo fill={iconsFill} />
          <Typography color={titleFill} variant="body1">
            Socket.io
          </Typography>
        </Box>
        <Box className={skill}>
          <Html fill={iconsFill} />
          <Typography color={titleFill} variant="body1">
            HTML
          </Typography>
        </Box>
        <Box className={skill}>
          <Css fill={iconsFill} />
          <Typography color={titleFill} variant="body1">
            CSS
          </Typography>
        </Box>
        <Box className={skill}>
          <Webflow fill={iconsFill} />
          <Typography color={titleFill} variant="body1">
            Webflow
          </Typography>
        </Box>
        <Box className={skill}>
          <Git fill={iconsFill} />
          <Box>
            <Typography color={titleFill} variant="body1">
              Git
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SkillsList;
