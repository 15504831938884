const colors = {
  white: "#FFFFFF",
  pink: "#659DBD",
  grey: "#9fbac9",
  shade: {
    dark: "#283F58",
    light: "#E7EDEF",
  },
  accent: {
    dark: "#69828D",
    light: "#999F87",
  },
  main: "#587B9E",
};

const theme = {
  color: {
    ...colors,
  },
  typography: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: 16,
    h1: {
      cursor: "default",
      fontFamily: "'Roboto', sans-serif",
      fontSize: "4.8rem",
      fontWeight: "500",
      "@media (max-width: 500px)": {
        fontSize: "4.3rem",
      },
      "@media (max-width: 400px)": {
        fontSize: "3.5rem",
      },

      "&::selection": {
        color: "#23C9FF",
        background: "#EC49A5",
      },
    },

    //Contact + Work
    h2: {
      fontFamily: "'Roboto', sans-serif",
      fontSize: "4.3rem",
      color: "#FFFFFF",
      fontWeight: "400",
      cursor: "default",
      "&::selection": {
        color: "#23C9FF",
        background: "#EC49A5",
      },
      "@media (max-width: 500px)": {
        fontSize: "4.1rem",
      },
      "@media (max-width: 400px)": {
        fontSize: "3.5rem",
      },
    },
    //Project Names,
    h3: {
      fontFamily: "'Lato', sans-serif",
      fontSize: "2rem",
      color: "#FFFFFF",
      fontWeight: "300",
      cursor: "default",
      "&::selection": {
        color: "#23C9FF",
        background: "#EC49A5",
      },
    },
    // Header Buttons (desktop)
    h4: {
      fontFamily: "'Lato', sans-serif",
      fontSize: "1.1rem",
      color: "#FFFFFF",
      "&::selection": {
        color: "#23C9FF",
        background: "#EC49A5",
      },
    },
    //Header Button (mobile)
    h5: {
      fontFamily: "'Lato', sans-serif",
      fontSize: "1rem",
      color: "#FFFFFF",
    },
    //"Front-End" Web Developer
    h6: {
      fontFamily: "'Lato', sans-serif",
      fontSize: "1.5rem",
      color: "#659DBD",
      cursor: "default",
      "&::selection": {
        color: "#23C9FF",
        background: "#EC49A5",
      },
      "@media (max-width: 400px)": {
        fontSize: "1.2rem",
      },
    },

    //TitleCard Buttons, Skills on TitleCard
    body1: {
      fontFamily: "'Lato', sans-serif",
      color: "#FFFFFF",
      fontWeight: "300",
      "&::selection": {
        color: "#23C9FF",
        background: "#EC49A5",
      },
      "@media (max-width: 400px)": {
        fontSize: ".9rem",
      },
    },
    //Project Description
    body2: {
      cursor: "default",
      "&::selection": {
        color: "#23C9FF",
        background: "#EC49A5",
      },
      fontFamily: "'Lato', sans-serif",
      fontWeight: "300",
      fontSize: "1.1rem",
      color: "#FFFFFF",
      "@media (min-width: 500px)": {
        fontSize: "1.3rem",
      },
    },
    //  Copy Email Text
    subtitle1: {
      fontFamily: "'Lato', sans-serif",
      fontSize: "1.4rem",
      fontWeight: "300",
      color: "#FFFFFF",
      "&::selection": {
        color: "#23C9FF",
        background: "#EC49A5",
      },
    },
    //Copied
    subtitle2: {
      fontFamily: "'Lato', sans-serif",
      fontSize: "1.2rem",
      fontWeight: "300",
      color: "#659DBD",
      "&::selection": {
        color: "#23C9FF",
        background: "#EC49A5",
      },
    },
  },
};

export const primary = {
  ...theme,
  palette: {
    primary: { main: "#f5f5f5" },
    secondary: { main: "#2c2c30" },
  },

  dark: true,
};

export const mutedInverse = {
  ...theme,
  palette: {
    primary: { main: "#2c2c30" },
    secondary: { main: "#f5f5f5" },
  },
  dark: false,
};

// Light Shade #E7EDEF
// Light Accent #999F87
// Main Brand #587B9E
// Dark Accent #69828D
// Dark Shade #283F58
