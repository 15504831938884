import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    paddingTop: "3rem",
  },
  projectDisplay: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  projectContainer: {
    margin: "1rem 0",
  },
}));

export default useStyles;
