import React from 'react';

import { Helmet } from 'react-helmet';
import {
  BrowserRouter,
  Route,
} from 'react-router-dom';

import { ColorProvider } from './contexts/ColorContext';
import Dashboard from './pages/Dashboard';

const App = () => (
  <>
    <Helmet>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="theme-color" content="#000000" />
      <meta
        name="description"
        content="Karl Secen. Full stack, web/mobile developer."
      />
      <meta property="og:title" content="Karl Secen - Personal Website" />
      <meta
        property="og:description"
        content="Karl Secen. Full stack, web/mobile developer."
      />
      <meta
        property="og:image"
        content="https://res.cloudinary.com/karlkris/image/upload/v1603210666/portfolio/header_site_ybj4pn.png"
      />
      <meta name="google" content="nositelinkssearchbox" />
      <meta name="robots" content="noarchive nosnippet" />
      <link rel="canonical" href="https://secen.ca" />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Material+Icons"
      />
      <style>{"body { background-color: #659DBD; }"}</style>
    </Helmet>
    <ColorProvider>
      <BrowserRouter>
        <Route path="/" component={Dashboard} />
      </BrowserRouter>
    </ColorProvider>
  </>
);

export default App;
