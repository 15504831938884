import React from "react";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  logo: (props) => ({
    width: props.width,
  }),
}));

const Logo = (props) => {
  const classes = useStyles(props);

  return (
    <>
      {props.dark === true ? (
        <img
          className={classes.logo}
          src="https://res.cloudinary.com/karlkris/image/upload/v1601831668/portfolio/logo/logo_bike_white_f1gobd.png"
          alt="logo-white"
        />
      ) : (
        <img
          className={classes.logo}
          src="https://res.cloudinary.com/karlkris/image/upload/v1601831629/portfolio/logo/logo_bike_black_y7qklp.png"
          alt="logo-black"
        />
      )}
    </>
  );
};

export default Logo;
