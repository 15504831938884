export const projectList = [
  {
    url: "https://res.cloudinary.com/karlkris/image/upload/v1601839712/portfolio/projects/cluewords_port_xvlt02.png",
    urlSmall:
      "https://res.cloudinary.com/karlkris/image/upload/v1602518666/github/cluewords_demo_ofen0l.gif",
    name: "Cluewords",
    alt: "cluewords_demo",
    display: "landscape",
    gitHub: "https://github.com/karlkristopher/cluewords",
    link: null,
    description: `A multiplayer web-based game based on the popular board\u00A0game\u00A0Codenames.\nClick the GitHub link for additional\u00A0demo\u00A0images.`,
    tech: "Built with React, Socket.io (real-time chat & gameplay), Express, Node.js, MongoDB, Redis, SendGrid, JWT,\u00A0and\u00A0AWS.",
  },
  {
    url: "https://res.cloudinary.com/karlkris/image/upload/v1601839704/portfolio/projects/berlincraft_port_vsqfrx.png",
    urlSmall:
      "https://res.cloudinary.com/karlkris/image/upload/v1596297702/github/berlincraft-demo_gnsmig.gif",
    name: "Berlin Craft",
    alt: "berlin-craft_demo",
    display: "portrait",
    gitHub: "https://github.com/karlkristopher/craftberlin",
    link: "https://berlincraft-beer.netlify.app",
    description:
      "Mobile-friendly directory of craft beer bars, brewhouses, and bottleshops around\u00A0Berlin.",
    tech: "MERN stack web application with the help of the Mapbox & Google\u00A0Places\u00A0APIs.",
  },
  {
    url: "https://res.cloudinary.com/karlkris/image/upload/v1601839704/portfolio/projects/apfelbananen_port_mwbm5s.png",
    urlSmall:
      "https://res.cloudinary.com/karlkris/image/upload/v1589711135/github/ab_demo_xtvcjn.gif",
    name: "Äpfel & Bananen",
    alt: "apfel-bananen_demo",
    display: "portrait",
    gitHub: "https://github.com/karlkristopher/apfel-bananen",
    link: "https://karlkristopher.github.io/apfel-bananen/",
    description: `Also known as la pipopipette, two players take turns drawing horizontal or vertical lines by clicking on adjacent dots to eventually make 1 by 1 boxes. If a player completes a box, they draw the next line. Whomever makes the most\u00A0boxes\u00A0wins.`,
    tech: "Built with JavaScript (p5.js) + HTML\u00A0&\u00A0CSS",
  },
  {
    url: "https://res.cloudinary.com/karlkris/image/upload/v1601839704/portfolio/projects/swapsies_port_rjjggx.png",
    urlSmall:
      "https://res.cloudinary.com/karlkris/image/upload/v1590504770/github/demo_sum4e3.gif",
    name: "Swapsies",
    alt: "swapsies_demo",
    display: "landscape",
    gitHub: "https://github.com/mak-on-track/swapsies",
    link: "https://swapsies-berlin.herokuapp.com/",
    description: `Create a personal profile along with items or services to swap, search, and browse items of other users to trade. Inspired by the trading app\u00A0Bunz`,
    tech: "Built with React, Express, Node.js, and\u00A0MongoDB",
  },
];
