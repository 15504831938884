import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
  container: {
    width: "600px",
    "@media (max-width: 800px)": {
      width: "95%",
    },
  },
  navigation: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tests: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: ".5rem",
  },
  badge: {
    marginRight: ".5rem",
  },
  linkButton: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    width: "5rem",
    border: `3px solid ${theme.color.pink}`,
    padding: ".6rem",
    borderRadius: ".3rem",
    cursor: "pointer",
    margin: "0rem 2rem",
    transition: "transform .1s",
    "&:hover": {
      backgroundColor: theme.color.grey,
    },
    "&:active": {
      transform: "scale(.95)",
      backgroundColor: "#EC49A5",
    },
  },

  themeButton: {
    marginTop: "2rem",
    width: "4.5rem",
    cursor: "pointer",
    transition: "transform .1s",
    "&:active": {
      transform: "scale(.95)",
    },
    "@media (max-width: 500px)": {
      width: "50px",
      marginTop: "1rem",
    },
    "@media (max-width: 350px)": {
      display: "none",
    },
  },
}));

export default useStyles;
