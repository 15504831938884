import React, { useContext, useState, useMemo } from "react";

const ColorContext = React.createContext();
const PaletteContext = React.createContext();

export function useColor() {
  return useContext(ColorContext);
}

export function usePalette() {
  return useContext(PaletteContext);
}

export function ColorProvider({ children }) {
  const [dark, setDark] = useState(true);
  const providerColor = useMemo(() => [dark, setDark], [dark, setDark]);

  const [paletteKey, setPaletteKey] = useState(1);
  const providerPalette = useMemo(() => [paletteKey, setPaletteKey], [
    paletteKey,
    setPaletteKey,
  ]);

  return (
    <ColorContext.Provider value={providerColor}>
      <PaletteContext.Provider value={providerPalette}>
        {children}
      </PaletteContext.Provider>
    </ColorContext.Provider>
  );
}
