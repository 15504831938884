import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
  container: {
    width: "70%",
    "@media (max-width: 500px)": {
      width: "95%",
    },
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginTop: "1rem",
    border: `3px solid ${theme.color.pink}`,
    borderRadius: "8px",
    padding: "1rem",
    minHeight: "25rem",
  },
  image: (props) => ({
    width: props.project.display === "portrait" ? "60%" : "70%",
    "@media (max-width: 500px)": {
      width: props.project.display === "portrait" ? "50%" : "70%",
    },
    borderRadius: "2%",
    display: "block",
    margin: ".3rem auto 0 auto",
  }),
  linkText: {
    color: theme.palette.primary.main
  },
  icon: {
    marginRight: ".3rem",
    "& svg": {
      display: "block",
      margin: "auto",
      height: "1.5rem",
    },
  },
  descText: {
    width: "70%",
    "@media (max-width: 500px)": {
      width: "95%",
    },
    "& p": {
      margin: "1rem 0",
    },
  },
  title: {
    margin: "1rem 0",
  },
  showIcons: {
    display: "flex",
    alignItems: "center",
    "& a": {
      textDecoration: "none",
    },
  },
  iconBubble: {
    margin: "0 .5rem",
    padding: ".3rem .6rem",
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "transform .1s",
    "&:hover": {
      backgroundColor: theme.color.grey,
    },
    "&:active": {
      transform: "scale(.95)",
      backgroundColor: "#EC49A5",
    },
  },
}));

export default useStyles;
