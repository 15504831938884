import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
  container: {
    paddingTop: "4rem",
  },
  icon: {
    width: "2.5rem",
    margin: "0 1rem",
    transition: "transform .1s",
    "&:active": {
      transform: "scale(.95)",
      fill: "#EC49A5",
    },
  },
  linkText: {
    textDecoration: "none",
  },
  emailLink: {
    transition: "transform .1s",
    "&:active": {
      transform: "scale(.95)",
    },
  },
  emailText: {
    textDecoration: "underline",
    "&:active": {
      color: "#EC49A5",
    },
  },
  themeButton: {
    margin: "6rem auto 0 auto",
    width: "auto",
    cursor: "pointer",
    transition: "transform .1s",
    "&:active": {
      transform: "scale(.95)",
    },
    "@media (max-width: 350px)": {
      display: "none",
    },
  },
  titleDivider: {
    borderTop: `7px solid ${theme.color.pink}`,
    width: "5rem",
    margin: "1.5rem auto 0 auto",
  },
  copyIcon: {
    color: theme.color.grey,
  },
  emailBorder: {
    border: `1px solid ${theme.palette.primary.main}`,
    margin: ".3rem",
    padding: ".3rem",
    borderRadius: ".3rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    flexDirection: "row",
    cursor: "pointer",
    transition: "transform .1s",
    "&:hover": {
      backgroundColor: theme.color.grey,
    },
    "&:active": {
      transform: "scale(.95)",
      backgroundColor: "#EC49A5",
    },
  },
}));

export default useStyles;
