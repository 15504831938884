import React from "react";

import { Link } from "react-scroll";

import { Box, Typography } from "@material-ui/core";

import { useColor } from "../../contexts/ColorContext";
import Logo from "../Images/Logo";
import SkillsList from "./SkillsList";
import useStyles from "./styles";

const TitleCard = (props) => {
  const { linkButton, container, navigation, themeButton, titleName } =
    useStyles(props);
  const [dark, setDark] = useColor();

  return (
    <Box
      className={container}
      id="title"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Typography
        className={titleName}
        align="center"
        variant="h1"
        color="primary"
      >
        Karl Secen
      </Typography>
      <Box m={3}>
        <Typography align="center" variant="h6">
          Full Stack, Web/Mobile&nbsp;Developer
        </Typography>
      </Box>
      <SkillsList />
      <Box m={3} className={navigation}>
        <Link to="projects" smooth duration={1000}>
          <Box className={linkButton}>
            <Typography color="primary" variant="body1">
              Work
            </Typography>
          </Box>
        </Link>
        <Link to="contact" smooth duration={1000}>
          <Box className={linkButton}>
            <Typography color="primary" variant="body1">
              Contact
            </Typography>
          </Box>
        </Link>
      </Box>
      <Box onClick={() => setDark((props) => !props)} className={themeButton}>
        <Logo width="100%" dark={dark} />
      </Box>
    </Box>
  );
};

export default TitleCard;
