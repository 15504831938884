import React from "react";
import { Box } from "@material-ui/core";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import PhoneIcon from "@material-ui/icons/Phone";
import { Link, animateScroll as scroll } from "react-scroll";
import useStyles from "./styles";

const BackToTop = (props) => {
  const classes = useStyles(props.showHeader);
  const { container, upButton, icon } = classes;

  return (
    <Box className={container}>
      <Link to="contact" smooth duration={1000}>
        <Box className={upButton} aria-label="scroll to contacts">
          <PhoneIcon className={icon} />
        </Box>
      </Link>
      <Box
        className={upButton}
        onClick={() => scroll.scrollToTop()}
        aria-label="scroll back to top"
      >
        <KeyboardArrowUpIcon className={icon} />
      </Box>
    </Box>
  );
};

export default BackToTop;
